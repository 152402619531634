import React, { Component, Fragment } from 'react';
// import ReactGA from 'react-ga';

//language file
// import { translate } from '../../i18n/translate';

// import { getContestState } from '../../util';
// import '../../styles/pages/_rules.scss';

const en_priv = `<!-- OneTrust Privacy Notice start -->

  <!-- Container in which the privacy notice will be rendered -->

  <div id="otnotice-5a437557-8805-49e7-ad04-33e955fd54cc" class="otnotice"></div>

 

  <script src="https://privacyportalde-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js" type="text/javascript" charset="UTF-8" id="otprivacy-notice-script">

      settings="eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC1kZS5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9"

    </script>

 

  <script type="text/javascript" charset="UTF-8">

      // To ensure external settings are loaded, use the Initialized promise:

      OneTrust.NoticeApi.Initialized.then(function() {

        OneTrust.NoticeApi.LoadNotices(["https://privacyportalde-cdn.onetrust.com/f69c0bf1-10ab-4d33-8b59-e235ddd37a5f/privacy-notices/5a437557-8805-49e7-ad04-33e955fd54cc.json"]);

      });

    </script>

   

  <!-- OneTrust Privacy Notice end -->`;

class Privacy extends Component{

    componentDidMount(){
        //initiate google analytics
        // ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render(){
        const langPreferance = this.props.lang;
        // const contestState = getContestState();
        return(
            <Fragment>
                <div id="main" className="main-wrapper rules">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                {langPreferance === 'fr-CA' ? 
                                    <div>
                                        <h1>Privacy Policy</h1>
                                    </div>
                                :
                                    <div>
                                        <h1>Privacy Policy</h1>
                                        <div dangerouslySetInnerHTML={{__html:en_priv}}></div>
                                    </div>
                                }
                                </div>
                            </div>
                        </div>
                </div>
            </Fragment>
        )
    }
}

export default Privacy;